<script lang="ts">
import CloseIcon from '~/assets/icons/close.svg'
import DropdownIcon from '~/assets/icons/dropdown.svg'
import EditIcon from '~/assets/icons/edit.svg'
import LockIcon from '~/assets/icons/lock.svg'
import LeaveIcon from '~/assets/icons/leave.svg'
import SettingsIcon from '~/assets/icons/settings.svg'
import SpinIcon from '~/assets/icons/spin.svg'
import CheckmarkIcon from '~/assets/icons/checkmark.svg'
import OnboardIcon from '~/assets/icons/onboard.svg'
import OffboardIcon from '~/assets/icons/offboard.svg'
import EquipmentIcon from '~/assets/icons/equipment.svg'
import EquipmentAddIcon from '~/assets/icons/equipment-add.svg'
import EquipmentRemoveIcon from '~/assets/icons/equipment-remove.svg'
import EquipmentSettingsIcon from '~/assets/icons/equipment-settings.svg'
import EquipmentSyncIcon from '~/assets/icons/equipment-sync.svg'
import SupportIcon from '~/assets/icons/support.svg'
import CalculationIcon from '~/assets/icons/calculation.svg'
import HelpIcon from '~/assets/icons/help.svg'
import CreditIcon from '~/assets/icons/credit-locked.svg'
import AtSignIcon from '~/assets/icons/at-sign.svg'
import BadgeIcon from '~/assets/icons/badge.svg'
import BackIcon from '~/assets/icons/back.svg'
import NavRight from '~/assets/icons/nav-right.svg'
import NavLeft from '~/assets/icons/nav-left.svg'
import addUserIcon from '~/assets/icons/addUser.svg'
import deleteUserIcon from '~/assets/icons/deleteUser.svg'
import manageUserIcon from '~/assets/icons/manageUser.svg'
import statisticsIcon from '~/assets/icons/statistics.svg'
import cycleIcon from '~/assets/icons/cycle.svg'

export type IconSize = (typeof ICON_SIZES)[number]
export type IconType = keyof typeof ICON_MAPPING

const ICON_SIZES = ['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl'] as const
export const ICON_MAPPING = {
  close: CloseIcon,
  checkmark: CheckmarkIcon,
  dropdown: DropdownIcon,
  edit: EditIcon,
  leave: LeaveIcon,
  lock: LockIcon,
  settings: SettingsIcon,
  spin: SpinIcon,
  onboard: OnboardIcon,
  offboard: OffboardIcon,
  equipment: EquipmentIcon,
  equipmentAdd: EquipmentAddIcon,
  equipmentRemove: EquipmentRemoveIcon,
  equipmentSettings: EquipmentSettingsIcon,
  equipmentSync: EquipmentSyncIcon,
  support: SupportIcon,
  calculation: CalculationIcon,
  help: HelpIcon,
  credit: CreditIcon,
  at: AtSignIcon,
  badge: BadgeIcon,
  back: BackIcon,
  right: NavRight,
  left: NavLeft,
  addUser: addUserIcon,
  deleteUser: deleteUserIcon,
  manageUser: manageUserIcon,
  statistics: statisticsIcon,
  cycle: cycleIcon,
} as const

export default defineComponent({
  props: {
    type: {
      type: String as PropType<IconType>,
      required: true,
      validator: (type: string) => Object.keys(ICON_MAPPING).includes(type),
    },
    size: {
      type: String as PropType<IconSize>,
      default: 'lg',
      validator: (size: IconSize) => ICON_SIZES.includes(size),
    },
  },
  setup(props) {
    const svg = computed(() => {
      const icon = ICON_MAPPING[props.type]
      if (!icon) {
        throw new TypeError(`Icons type "${props.type}" not found`)
      }
      return icon
    })

    return { svg }
  },
})
</script>

<template>
  <component
    :is="svg"
    :data-testid="type"
    fill="currentColor"
    class="Icon"
    :class="[size, { Loading: type === 'spin' }]"
  />
</template>

<style scoped>
.Icon.sm {
  @apply h-4 w-4;
}

.Icon.md {
  @apply h-5 w-5;
}

.Icon.lg {
  @apply h-6 w-6;
}

.Icon.xl {
  @apply h-10 w-10;
}

.Icon.xxl {
  @apply h-12 w-12;
}

.Icon.xxxl {
  @apply h-16 w-16;
}

.Icon.xxxxl {
  @apply h-24 w-24;
}

.Icon.Loading {
  @apply animate-spin;
}
</style>
