import type { FlowConfig, FlowContext } from '../types'
import Tiles from '~/components/base/Tiles.vue'

const adminTilesConfig = [
  { id: 'manageEquipment', title: 'Equipment verwalten', icon: 'equipmentSync', disabled: false },
  { id: 'statistics', title: 'Equipment Statistik', icon: 'statistics', disabled: false },
  { id: 'manageUser', title: 'Nutzer verwalten', icon: 'manageUser', disabled: false },
  { id: 'updateEmployees', title: 'Mitarbeiterliste aktualisieren', icon: 'cycle', disabled: false },
]

const flow: FlowConfig = {
  id: 'admin',
  icon: 'settings',
  title: 'Service Point konfigurieren',
  tileTitle: 'Nutzer verwalten',
  isAdmin: true,
  steps: [
    {
      id: 'select',
      component: Tiles,
      isKeyboardShown: false,
      onComponentEvents: {
        click: (context: FlowContext, tileId: string) => context.navigateByFlowId(tileId),
      },
      getComponentProps: () => ({
        allTiles: adminTilesConfig,
      }),
    },
  ],
}

export default flow
